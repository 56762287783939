import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab, } from "react-bootstrap";
import "./ValuationStepsBanner.scss";

const ValuationStepsBanner = (props) => {
  return (
    <React.Fragment>
      
     <Container className="container-banner">
        <Row>
          <Col lg={12}>
          <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
              <h4 className="small-heading">Free Property Valuation</h4>
              <h1>Find the value of your home</h1>
              <p className="banner-p-content">Our local property expert will visit your property, allowing your home to live up to its true selling potential, ensuring you get the right price.</p>
            </ScrollAnimation>

          </Col>
        </Row>
      </Container> 
            
      
    
    </React.Fragment>
  );
};
export default ValuationStepsBanner;
