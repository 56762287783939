import React from "react";
import ValuationStepsBanner from "./ValuationStepsBanner/ValuationStepsBanner";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab, } from "react-bootstrap";
import Steps from "./Steps/Steps";
import GoogleReviewValuationStep from "./GoogleReviewValuationStep/GoogleReviewValuationStep";
import CompanyInformation from "./CompanyInformation/CompanyInformation";
const ValuationStepsComponent = () => {
  return (
    <React.Fragment>
       <section className="valuation-steps-banner">
       <ValuationStepsBanner/>
      <Container>
        <Row>
          <Col xl={8} className="tab-column">
          <Steps/>
          <div className="d-md-none"><GoogleReviewValuationStep/></div>
          </Col>
          <Col xl={4} className="company-detail-column">
              <CompanyInformation/>
          </Col>
        </Row>
        <Row className="d-none d-md-block"><Col xl={12}><GoogleReviewValuationStep/></Col></Row>
      </Container>
    </section>
    </React.Fragment>
    );
};
export default ValuationStepsComponent;
