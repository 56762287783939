import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab, } from "react-bootstrap";
import "./CompanyInformation.scss";

import GoogleReviewImgD from "../../../images/ValuationSteps/google-reviews-valuation-steps-d.png";
import GoogleReviewImgT from "../../../images/ValuationSteps/google-reviews-valuation-steps-t.png";
import GoogleReviewImgM from "../../../images/ValuationSteps/google-reviews-valuation-steps-m.png";

const CompanyInformation = (props) => {
  return (
    <React.Fragment>
      <div className="company-info">
        <div className="contact-details">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
        <div className="info-block">
          <div className="icon-block">
            <i className="icon-phone-yellow-m"></i>
          </div>
          <div className="info-block-dt">
            <span className="info-block-head">Telephone</span>
            <span className="info-content"><Link href="tel:0207 923 9222" className="btn-text-link">0207 923 9222</Link></span>
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
        <div className="info-block">
          <div className="icon-block">
            <i className="icon-email-yellow-m"></i>
          </div>
          <div className="info-block-dt">
            <span className="info-block-head">Email</span>
            <span className="info-content"><Link href="mailto:hello@locationlocation.com" className="btn-text-link">hello@locationlocation.com</Link></span>
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
        <div className="info-block mbot-xs">
          <div className="icon-block">
            <i className="icon-map-marker-yellow-m"></i>
          </div>
          <div className="info-block-dt address">
            <span className="info-block-head">Address</span>
            <span className="info-content">18 Stoke Newington, Church Street, Stoke Newington, N16 0LU </span>
            <span className="info-content"><Link href="#" className=" border-bottom-yellow">View on a Map</Link></span>
          </div>
        </div>
        </ScrollAnimation>
        </div>
        <div className="company-timing-block">
        <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">


          <div className="info-block">
          <div className="icon-block">
            <i className="icon-clock-yellow-m"></i>
          </div>
          <div className="info-block-dt address">
            <span className="info-block-head">Opening Hours</span>
            <ul>
              <li><span class="openh">Monday</span>09:00 to 18:30</li>
              <li><span class="openh">Tuesday</span>09:00 to 18:30</li>
              <li><span class="openh">Wednesday</span>09:00 to 18:30</li>
              <li><span class="openh">Thursday</span>09:00 to 18:30</li>
              <li><span class="openh">Friday</span>09:00 to 18:30</li>
              <li><span class="openh">Saturday</span>10:00 to 16:30</li>
              <li><span class="openh">Sunday</span>Closed</li>
            </ul>
          </div>
        </div>
        </ScrollAnimation>
        
        </div>
      </div>
    </React.Fragment>
  );
};
export default CompanyInformation;
