import React, {useEffect, useState} from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import ValuationStepsComponent from "../../Components/ValuationSteps/ValuationStepsComponent";

const ValuationSteps = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 30)
    })
  }, [])

  return (
    <React.Fragment>
      <div className={scroll ? "menu-fixed menu2 menu-scroll" : "menu-fixed menu2"}><Header/></div>
      <ValuationStepsComponent />
      <div className="valuation-steps-footer"><Footer/></div>
    </React.Fragment>
    );
};
export default ValuationSteps;
